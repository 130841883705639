/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
}

.App {
  text-align: center;
}



.App-logo {
  width: 80px;
  margin-bottom: 1rem;
}

.App-header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.App-header p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.App-buttons .App-link {
  color: #fff;
  background-color: #28a745;
  padding: 0.8rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.App-buttons .App-link:hover {
  background-color: #218838;
}

.hero-header {
  position: relative;
  width: 100%;
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* dark overlay for readability */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.hero-content {
  color: white;
  max-width: 700px;
  text-align: center;
  font-family: 'BurfordBase';
  src: url('./fonts/Burford_Base.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.hero-content h1 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
}

.hero-content p {
  font-size: 1.5rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.hero-content .highlight {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 4rem;
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  z-index: 999;
  transition: transform 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #1ebe5d;
  transform: scale(1.1);
}

.gpt-button {
  position: fixed;
  bottom: 90px; /* un poco arriba del botón de WhatsApp */
  right: 20px;
  background-color: #10a37f; /* color tipo ChatGPT */
  color: white;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  z-index: 998;
  transition: transform 0.3s ease;
}

.gpt-button:hover {
  background-color: #0e8a6b;
  transform: scale(1.1);
}


.icon {
  font-size: 24px;
}



/* Section Styles */
section {
  padding: 3rem 2rem;
}

section h2 {
  font-size: 2rem;
  color: #00C4B0;
  margin-bottom: 1rem;
}

section p {
  font-size: 1rem;
  color: #f8fbfa;
  margin-bottom: 1rem;
  max-width: 700px;
  margin: 0 auto 1.5rem;
}



.custom-logo {
  width: 80px;
  height: 80px;
  background-color: #2E2A25;
  object-fit: contain;
}

@media (min-width: 768px) {
  .custom-logo {
    width: 100px;
    height: 100px;
  }

  .logo-container {
    padding: 60px;
  }
}


.package-card {
  background: linear-gradient(135deg, #2E2A25, #2E2A25);

  color: #f6f4f4;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  max-width: 400px;
  margin: auto;
  box-shadow: 0 8px 16px rgba(121, 45, 45, 0.2);
  transition: transform 0.3s ease;
}

.package-card:hover {
  transform: scale(1.03);
}

.package-title {
  font-size: 1.6em;
  margin-bottom: 10px;
  font-weight: bold;
  color: #00C4B0;
}

.package-description {
  font-size: 1em;
  margin-bottom: 15px;
  text-decoration-color: #007bff;
}

.package-benefits {
  list-style-type: none;
  padding: 0;
  font-size: 0.95em;
}

.package-benefits li {
  margin-bottom: 8px;
}

.package-price {
  font-size: 1.2em;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  margin: 15px 0;
}

.price-label {
  font-weight: bold;
}

.package-availability {
  font-size: 0.9em;
  margin-bottom: 15px;
}

.package-button {
  background: #00C4B0;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 1em;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
  color: #2E2A25;
}

.package-button2 {
  background: #f6f8f8;
  color: rgb(22, 19, 19);
  border: none;
  padding: 12px 20px;
  font-size: 2em;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
  color: #2E2A25;
}

.package-button:hover {
  background: #009E90;
  color: #2E2A25;
}



.packages {
  display: flex; /* Enables flexbox layout */
  flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
  column-gap: 15px; /* Space between columns */
}

/* Package Styles */
#packages .package {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 1rem auto;
}

#packages .package h3 {
  color: #00C4B0;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

#packages .package p {
  color: #ffffff;
  margin-bottom: 2rem;
}

#packages .package strong {
  font-size: 1.1rem;
  color: #fffefe;
}

.daily-package {
  position: relative;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent for glassmorphism effect */
  backdrop-filter: blur(10px); /* Blur the background */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border for depth */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Smooth shadow */
  max-width: 90%; /* Responsive width */
  margin: 1.5rem auto;
  padding: 2rem;
  overflow: hidden; /* Avoid content spilling */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add hover animation */
}

.daily-package:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
}

.daily-package h3 {
  color: rgb(245, 241, 239); /* Neon color for a futuristic vibe */
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center; /* Center-align text */
}

.daily-package p {
  color: #fff; /* White text for contrast */
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  text-align: center; /* Center-align paragraphs */
}

.daily-package strong {
  color: rgb(241, 248, 248); /* Highlight strong text */
}
.daily-package span {
  color: rgb(243, 249, 249); /* Highlight strong text */
}

@media (max-width: 768px) {
  .daily-package {
    padding: 1.5rem;
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .daily-package h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .daily-package {
    padding: 1rem;
    font-size: 0.9rem; /* Further adjust font size for small phones */
  }

  .daily-package h3 {
    font-size: 1.6rem;
  }
}


/* Reservations Section */
#reservations a {
  background-color: #007bff;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

#reservations a:hover {
  background-color: #0056b3;
}

/* Footer Styles */
.App-footer {
  background-color: #333;
  color: #fff;
  padding: 2rem;
  font-size: 0.9rem;
}

.App-footer a {
  color: #28a745;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (min-width: 768px) {
  .App-header h1 {
    font-size: 3rem;
  }

  .App-header p {
    font-size: 1.5rem;
  }

  #packages {
    display: flex;
  }

  #packages .package {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
  }

  section h2 {
    font-size: 2.5rem;
  }

  .App-buttons .App-link {
    padding: 1rem 2rem;
  }
}
